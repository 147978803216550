import { SelectInput} from 'react-admin'
import * as React from 'react'
import {
    SubscriptionPeriodListDay,
    SubscriptionPeriodListHour,
    SubscriptionPeriodListMonth,
    SubscriptionPeriodListWeek
} from 'src/types/enum/SubscriptionPeriod'
import {SubscriptionTimeUnit} from 'src/types/enum/SubscriptionTimeUnit'
import {useMemo} from 'react'


interface Props{
    source?: string
    label?: string
    disabled?: boolean;
    allowEmpty?: boolean
    resettable?: boolean
    alwaysOn?: boolean
    fullWidth?: boolean
    timeUnit: SubscriptionTimeUnit | undefined
    variant?:  'standard' | 'outlined' | 'filled';
    validate?: any
}
const SubscriptionTimeUnitPeriodInput = (props: Props) => {
    const options = useMemo(() => {
        switch (props.timeUnit){
            case SubscriptionTimeUnit.Month:
                return SubscriptionPeriodListMonth
            case SubscriptionTimeUnit.Week:
                return SubscriptionPeriodListWeek
            case SubscriptionTimeUnit.Day:
                return SubscriptionPeriodListDay
            case SubscriptionTimeUnit.Hour:
                return SubscriptionPeriodListHour
            default:
                return []
        }
    }, [props.timeUnit])
return (        <SelectInput source={props.source} label={props.label}  disabled={props.disabled} resettable={props.resettable}   variant={props.variant} validate={props.validate} fullWidth={true} choices={options}  {...props} />
)
}

export default SubscriptionTimeUnitPeriodInput
