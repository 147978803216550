
export enum FeatureKind {
  Autoapply = 'autoapply', //
  CvAnalize = 'cvAnalize', //Детальный разбор и рекомендации по резюме
  CvSelect = 'cvSelect', //Выделить резюме в поиске
  CvUp = 'cvUp', //not use Поднять резюме в поиске
  ProposalRemind = 'proposalRemind', //Напомнить о своем отклике (через неделю по кнопке)
  ProposalSuper = 'proposalSuper', //Суперотклик (не просто отклик, но и сообщение вдогонку)
  ProfileViews = 'profileViews', //Кто просматривал ваш профиль
  TopCandidates = 'topCandidates', //Где ты в топе кандидатов
  VacancyStatistic = 'vacancyStatistic', //Статистика по вакансии (сколько уже откликнулось)
}

export const FeatureKindList = [
  {id: FeatureKind.Autoapply, name: 'AutoApply'},
  {id: FeatureKind.CvAnalize, name: 'Детальный разбор и рекомендации по резюме'},
  {id: FeatureKind.CvSelect, name: 'Выделить резюме в поиске'},
  {id: FeatureKind.CvUp, name: 'Поднять резюме в поиске'},
  {id: FeatureKind.ProposalRemind, name: 'Напомнить о своем отклике'},
  {id: FeatureKind.ProposalSuper, name: 'Суперотклик'},
  {id: FeatureKind.ProfileViews, name: 'Кто просматривал ваш профиль'},
  {id: FeatureKind.TopCandidates, name: 'Где ты в топе кандидатов'},
  {id: FeatureKind.VacancyStatistic, name: 'Статистика по вакансии'},

]
