import * as React from 'react'
import {RecordContextProvider, TextInput, useNotify, useRefresh} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import { FieldValues } from 'react-hook-form'
import {useState} from 'react'
import {RequestError} from 'src/types/types'
import ProfileRepository from 'src/data/repositories/ProfileRepository'
import {useRecordContext} from 'ra-core'
interface Props {
  isShown?: boolean
  onClose?: () => void
}
export const ProfileBlockModal = (props: Props) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const record = useRecordContext()
  const [sending, setSending] = useState(false)
  const handleSubmit = async (data: FieldValues) => {
    try {
      setSending(true)
      if(record.blocked) {
        await ProfileRepository.unBlock({profileId: record.id!})
        notify('Пользователь Разблокирован', { type: 'success' })
      }else{
        await ProfileRepository.block({profileId: record.id!})
        notify('Пользователь заблокирован', { type: 'success' })
      }
      refresh()
      console.log('props.onClose', props.onClose)
      if (props.onClose) {
        props.onClose()
      }
    }catch (e) {
      console.error(e)
      if (e instanceof RequestError) {
        notify(e.message, { type: 'error' })
      }
    }
    setSending(false)
    return true
  }

  return (
      <RecordContextProvider value={{}}>
        <ModalForm
            isShown
            defaultValues={{}}
            fullWidth={true}
            maxWidth={'xs'}
            resource={'profile'}
            title={''}
            onClose={props.onClose}
            save={handleSubmit}
            isLoading={sending}
        >
          <TextInput source={'blockComment'} label={'Block comment'} helperText={'Will be send in notification'} variant={'outlined'} fullWidth={true}/>
        </ModalForm>
      </RecordContextProvider>
  )
}
