export type Nullable<T> = T | null
export type Nillable<T> = T | null | undefined

export class RequestError extends Error {
  message: string
  status: number
  code: string
  isNotFoundError: boolean

  constructor(message: string, status: number, code: string) {
    super(message)
    this.message = message
    this.status = status
    this.code = code
    this.isNotFoundError = status === 404
  }
}
