
import request from 'src/utils/request'

export default class AiCvEvaluationRepository {
  static async resend(data: {
    id: number | string
  }): Promise<void> {
    return request<void>({
      url: `/admin/api/ai-cv-evaluation-request/${data.id}/resend`,
      method: 'put',
      data,
    })
  }
}
