
import request from 'src/utils/request'

export default class ProfileRepository {
  static async block(data: {
    profileId: number | string
  }): Promise<void> {
    return request<void>({
      url: `/admin/api/profile/${data.profileId}/block`,
      method: 'post',
      data,
    })
  }

  static async unBlock(data: {
    profileId: number| string
  }): Promise<void> {
    return request<void>({
      url: `/admin/api/profile/${data.profileId}/unblock`,
      method: 'post',
      data,
    })
  }
}
