import * as React from 'react'
import {FC} from 'react'
import {
  EditProps,
} from 'react-admin'
import TariffFeatureForm from 'src/resources/tariff-feature/TariffFeatureForm'
import {CustomEdit} from 'src/components/CustomEdit'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Tariff Feature {record.name}</span> : null

const TariffFeatureEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title/>}>
    <TariffFeatureForm/>
    </CustomEdit>
  )
}
export default TariffFeatureEdit