import {DateRangePicker, DateRange} from 'mui-daterange-picker'
import React, {ComponentProps, ReactElement, useCallback, useRef} from 'react'
import {FieldTitle, useInput} from 'ra-core'
import {format as dateFormat} from 'date-fns'
import { useTranslate} from 'react-admin'
import styles from './index.module.scss'
import {InputHelperText, ResettableTextField} from 'ra-ui-materialui'
import clsx from 'clsx'

export interface Props {
    label?: string | ReactElement | boolean;
    source: string;
    fullWidth?: boolean;
    helperText?: ComponentProps<typeof InputHelperText>['helperText'];
    record?: Record<any, any>;
    resource?: string;
    variant?: string;
    useUTC?: boolean;
    [key: string]: any;
}

const DateRangeFilterInput = (props: Props) => {
    const {...fieldProps} = props

    const {
        options,
        label,
        source,
        fullWidth,
        format,
        parse,
        resource,
        className,
        providerOptions,
        validate,
        helperText,
        ...rest
    } = fieldProps
    const [open, setOpen] = React.useState(false)
    const translate = useTranslate()
    const toggle = () => setOpen(!open)
        const refInput = useRef()
    const {
        id,
        isRequired,
        formState: { isSubmitted },
        field: {value, onChange: onChangeInput, ...fieldRest},
        fieldState: { error, invalid, isTouched },
    } = useInput({source, ...rest})


    const handleChange = useCallback((value: any) => {
        if(!value){
            onChangeInput(value)
        }
        console.log('HandleChange', value)
        console.log('DateProps', props)
        // eslint-disable-next-line quotes
        onChangeInput(!props.useUTC ? `${value.startDate.toISOString()},${value.endDate.toISOString()}` : `${dateFormat(value.startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")},${dateFormat(value.endDate, "yyyy-MM-dd'T23:59:59.999Z'")}`)

    }, [])

    const formatDate = (date: string) => {
        if (!date) {
            return ''
        }
        console.log('formatDate', date)
        try {
            return dateFormat(new Date(date), 'dd.MM.yy')
        } catch (e) {
            return ''
        }
    }
    const formatValue = (value: any) => {
        if (!value){
            return
        }
        const valueParts = value?.split(',')
        return `${formatDate(valueParts[0])} - ${formatDate(valueParts[1])}`
    }
    const getInitialDateRange = (): DateRange => {
        if (!value || !value.between) {
            return {
                startDate: new Date(),
                endDate: new Date()
            }
        }
        const valueParts = value?.split(',')
        return {
            startDate: new Date(valueParts[0]),
            endDate: new Date(valueParts[1])
        }
    }

    console.log('Value11', 'value', value)
    const renderHelperText =
        helperText !== false || ((isTouched || isSubmitted) && invalid)
    return (
        <>

            <ResettableTextField
                id={id}
                {...fieldRest}
                ref={refInput}
                value={formatValue(value)}
                onChange={(e) => {
                    console.log('refInput.current', refInput.current)
                    onChangeInput(null)
                    if(refInput.current) {
                        (refInput.current as any).value = ''
                    }
                }}
                resettable
                onReset={( )=> {
                    console.log('ONReset111')
                    onChangeInput(null)
                }}
                className={clsx('ra-input', `ra-input-${source}`, className)}
                label={
                    props.label !== '' && props.label !== false ? (
                        <FieldTitle
                            label={label}
                            source={source}
                            resource={resource}
                            isRequired={isRequired}
                        />
                    ) : null
                }
                error={(isTouched || isSubmitted) && invalid}
                onClick={() => toggle()}
                helperText={
                    renderHelperText ? (
                        <InputHelperText
                            touched={isTouched || isSubmitted}
                            error={error?.message}
                            helperText={helperText}
                        />
                    ) : null
                }
            />


            <div className={styles.wrapper}>
                {<DateRangePicker
                    initialDateRange={getInitialDateRange()}
                    open={open}
                    wrapperClassName={styles.wrapper}
                    toggle={toggle}
                    onChange={(range: DateRange) => handleChange(range)}
                />}
            </div>

        </>
    )
}


export default DateRangeFilterInput
