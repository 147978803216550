import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid, SelectField,TextField
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import TransTextField from 'src/components/fields/TransTextField'
import { SubscriptionAddonKindList } from 'src/types/enum/SubscriptionAddonKind'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'TariffAddon'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>

        </Filter>
    )
}

const TariffAddonList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Tariff Addons"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'No Data'} description={'You can create'}
                              buttonText={'Create'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <TransTextField source={'name'} label={'Name'}/>
                 <SelectField source={'kind'} label={'Kind'} choices={SubscriptionAddonKindList}/>
                <TextField source={'sort'} label={'Sort'}/>
                <TextField source={'limit'} label={'Limit'}/>

            </Datagrid>
        </List>
    )
}

export default TariffAddonList
