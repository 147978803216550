import ListIcon from '@mui/icons-material/List'
import TariffFeatureList from 'src/resources/tariff-feature/TariffFeatureList'
import TariffFeatureEdit from 'src/resources/tariff-feature/TariffFeatureEdit'
import TariffFeatureCreate from 'src/resources/tariff-feature/TariffFeatureCreate'

export default {
  create: TariffFeatureCreate,
  edit: TariffFeatureEdit,
  list: TariffFeatureList,
  icon: ListIcon,
}