import * as React from 'react'
import Formatter from 'src/utils/formatters'
import {
  SimpleForm,
  FormTab,
  SelectInput,
  required,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  NumberInput,
} from 'react-admin'
import {ChangeEventHandler} from 'react'
import {useFormContext} from 'react-hook-form'
import SubscriptionTimeUnitPeriodInput from 'src/components/inputs/SubscriptionTimeUnitPeriodInput'
import {SubscriptionTimeUnit, SubscriptionTimeUnitList} from 'src/types/enum/SubscriptionTimeUnit'
import {SubscriptionAddonKind, SubscriptionAddonKindList} from 'src/types/enum/SubscriptionAddonKind'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    </FormTab>)
}
const TariffAddonForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <SelectInput source="kind" label={'Kind'} variant={'outlined'} choices={SubscriptionAddonKindList} validate={required()} fullWidth={true}/>
      <ArrayInput source="translations" label={'Content'}  validate={required()}>
        <SimpleFormIterator fullWidth>
          <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={[{id: 'en', name: 'EN'}, {id: 'id', name: 'Bahasa'}]} validate={required()} />
          <TextInput source="name" label={'Name'} variant={'outlined'} fullWidth validate={required()} />

          <TextInput source="description" label={'Description'} multiline variant={'outlined'} fullWidth  />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="priceByPeriod" label={'Prices by periods'} >
        <SimpleFormIterator fullWidth disableReordering={true} inline >
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData, getSource, ...rest }) => formData.kind !== SubscriptionAddonKind.AiCvEvaluationUnLimit &&
                (<SubscriptionTimeUnitPeriodInput source={getSource('period')}  label={'Period'}  timeUnit={SubscriptionTimeUnit.Month} variant={'outlined'} disabled = {formData.kind === SubscriptionAddonKind.AiCvEvaluationUnLimit ? true : false} fullWidth={true} {...rest} />)
            }
          </FormDataConsumer>
          <NumberInput source="price" label={'Price'} helperText={false}  variant={'outlined'} fullWidth={false}  />
          <FormDataConsumer fullWidth={true} variant={'outlined'}>
            {({ formData, getSource, ...rest }) => formData.kind !== SubscriptionAddonKind.AiCvEvaluationUnLimit &&
                (<SelectInput source="timeUnit" label={'Time Unit'}    variant={'outlined'} fullWidth={true} choices={SubscriptionTimeUnitList}  />)
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>

      <NumberInput source="limit" label={'Limit'} variant={'outlined'} fullWidth  />
      <NumberInput source="sort" label={'Sort'} variant={'outlined'} fullWidth  />
    </SimpleForm>
  )
}
export default TariffAddonForm
