import Cookies from 'js-cookie'
import queryString from 'query-string'
import { CookiesType } from 'src/types/enums'
import { Nillable, RequestError } from 'src/types/types'
interface Options {
  url: string
  method?: 'post' | 'put' | 'get' | 'delete' | 'patch'
  data?: any
  token?: Nillable<string>
  sessionId?: Nillable<string>
  file?: File
  disableCache?: boolean
  accountId?: Nillable<number>
  revalidate?: Nillable<number>
  signal?: Nillable<AbortSignal>
}

async function request<T = any>(options: Options): Promise<T> {
  let url = ''
  let method = 'get'
  let data: any = null
  let file: File | null = null
  let accountId: Nillable<number>
  let disableCache = false

  url = options!.url
  method = options.method?.toLowerCase() ?? 'get'
  data = options.data
  file = options.file ?? null
  accountId = options.accountId
  disableCache = options.disableCache ?? false

  const correctUrl = `${process.env.REACT_APP_API_URL || ''}${url}${method === 'get' && data ? `?${queryParams(data)}` : ''}`

  const mulipartFormData = typeof FormData == 'undefined' ? null : new FormData()
  if (file && mulipartFormData) {
    mulipartFormData.append('file', file)
  }

  const headers: HeadersInit = {}

  if (!file) {
    headers['Content-Type'] = 'application/json'
  }
  if (accountId) {
    headers['account-id'] = `${accountId}`
  }

  if (typeof window !== 'undefined' && Cookies.get(CookiesType.accessToken)) {
    headers['Authorization'] = `Bearer ${Cookies.get(CookiesType.accessToken)}`
  }

  const res = await fetch(correctUrl, {
    method,
    headers,
    ...(method !== 'get' ? { body: JSON.stringify(data) } : {}),
    ...(options.revalidate ? { next: { revalidate: options.revalidate } } : { cache: 'no-store' }),
    ...(options.signal ? { signal: options.signal } : {}),
  })

  const jsonData = await res.json()
  if (res.status === 200 || res.status === 201) {
    return jsonData
  }
  if (res.status === 401) {
    Cookies.remove(CookiesType.accessToken)
    if (typeof window !== 'undefined') {
      //window.location.replace(Routes.login())
    }
  }
  throw new RequestError(jsonData?.errors || res.statusText || 'Ошибка', res.status ?? 500, jsonData?.errorCode)
}

function queryParams(params: { [key: string]: any }) {
  return queryString.stringify(params)
}

export default request

export function catchNotFoundError(err: any): void {
  if (err instanceof RequestError && err.isNotFoundError) {
    return
  }
  throw err
}
