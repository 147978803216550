import * as React from 'react'
import { SimpleForm, SelectInput, NumberInput,
} from 'react-admin'
import {FeatureKindList} from 'src/types/enum/FeatureKind'

const TariffFeatureForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <SelectInput source={'kind'} label={'Kind'} choices={FeatureKindList}/>
      <NumberInput source={'price'} label={'Price'} />
    </SimpleForm>
  )
}
export default TariffFeatureForm
