
export enum SubscriptionAddonKind {
  AiCvEvaluationUnLimit = 'aiCvEvaluationUnLimit',
  AiCvEvaluationLimit = 'aiCvEvaluationLimit',
  VacancyLimit = 'vacancyLimit',
}

export const SubscriptionAddonKindList = [
  {id: SubscriptionAddonKind.AiCvEvaluationUnLimit, name: 'Ai Cv Evaluation Limit for Score'},
  {id: SubscriptionAddonKind.AiCvEvaluationLimit, name: 'Ai Cv Evaluation Limit'},
  {id: SubscriptionAddonKind.VacancyLimit, name: 'Vacancy Limit'}
]
