import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    DateField,
    BooleanInput,
    ReferenceField,
    Datagrid, TextField, SearchInput,
} from 'react-admin'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {SubscriptionKindList} from 'src/types/enum/SubscriptionKind'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'id': 'Company ID',
            'email': 'Email',
            'company': 'Company name',
            'phone': 'Phone',
            'referralCode': 'Referal Code',
            'total_vacancies': 'Candidates',
            'total_cvs': 'Uploaded CVs',
            'total_cv_evaluation': 'CVs Scored',
            'trialEndAt': 'Trial End',
            'createdAt': 'Registration Date',
            'lastVacancyPublishedAt': 'Last Posting',
            'tariffKind': 'Plan',
            'managers_count': 'Recruiters'
        },
        data: posts.map((item: any) => ({
            ...item,
            tariffKind: SubscriptionKindList.find(i => i.id === item.tariffKind)?.name,
            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Company'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source={'search'} variant={'outlined'} alwaysOn={true} />
            <BooleanInput source={'companyNameExists'} variant={'outlined'} label={'Только с именем'} alwaysOn={true} />
        </Filter>
    )
}

const DashboardCompany: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Companies"

            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'No Data'} hasCreate={false}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'companyId'} label={'ID'} type={'edit'} resource={'company'} sortable={false}/>

                <TextField source={'email'} label={'Email'}  sortable={false}/>
                <TextField source={'company'} label={'Company Name'}  sortable={false}/>
                <TextField source={'phone'} label={'Phone'}  sortable={false}/>
                <TextField source={'referralCode'} label={'Referral Code'}  sortable={false}/>
                <DateField source={'lastLoginAt'} label={'Last login'}  showTime={true}  sortable={false}/>
                <DateField source={'proposal_updated_at'} label={'Last Respond'} showTime={true}  sortable={false}/>
                <TextField source={'total_vacancies'} label={'Vacancies'}  sortable={false}/>
                <TextField source={'candidates_count'} label={'Candidates'}  sortable={false}/>
                <TextField source={'total_cvs'} label={'Uploaded CVs'}  sortable={false}/>
                <TextField source={'total_cv_evaluation'} label={'CV scored'}  sortable={false}/>
                <DateField source={'trialEndAt'} label={'Trial End'}  sortable={false}/>
                <DateField source={'createdAt'} label={'Registration Date'}  sortable={false}/>
                <DateField source={'lastVacancyPublishedAt'} label={'Last Posting'}  sortable={false}/>
                <ReferenceField source="activeTariffId" reference="tariff" label={'Plan'}  sortable={false}>
                    <TransTextField source={'name'} label={'Name'}  sortable={false}/>
                </ReferenceField>
                <TextField source={'managers_count'} label={'Recruiters'} sortable={false}/>
            </Datagrid>
        </List>
    )
}

export default DashboardCompany
