import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    SelectField,
    FunctionField,
    Datagrid, DateField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import CvLinkField from 'src/components/fields/CvLinkField'
import AssetField from 'src/components/fields/AssetField'
import {AiCvRequestStatusList} from 'src/types/enum/AiCvRequestStatus'
import {AiCvRequestTypeList} from 'src/types/enum/AiCvRequestType'
import {DropdownMenu} from 'src/components/DropdownMenu/DropdownMenu'
import {AiCvRequestResendButton} from 'src/resources/ai-cv-request/AiCvRequestResendButton'


const ActionFields = () => {
    return (<DropdownMenu>
        <AiCvRequestResendButton buttonView={'menuItem'}/>
    </DropdownMenu>)
}

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'AiCvRequest'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>

        </Filter>
    )
}

const AiCvRequestList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="AI запрос резюме"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'No Data'} description={'You can create'}
                              buttonText={'Create'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'} type={'show'}/>
                <SelectField source={'type'} label={'Type'} choices={AiCvRequestTypeList}/>
                <SelectField source={'status'} label={'Status'} choices={AiCvRequestStatusList}/>
                <CvLinkField source={'cv'} label={'Cv'}/>
                <AssetField source={'file'} label={'File'}/>
                <DateField source={'finishAt'} label={'Finished'} showTime/>
                <FunctionField source={'reqDuration'} label={'Duration'} render={(i: any) => `${(i.reqDuration / 1000).toFixed(2)} сек`}/>
                <DateField source={'createdAt'} label={'Created'} showTime/>
                <ActionFields/>
            </Datagrid>
        </List>
    )
}

export default AiCvRequestList
