import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    TextField,
    ListProps,
    Datagrid, SelectField, DateField, FunctionField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import {AiCvRequestStatusList} from 'src/types/enum/AiCvRequestStatus'
import CvLinkField from 'src/components/fields/CvLinkField'
import VacancyLinkField from 'src/components/fields/VacancyLinkField'
import {AiEngineList} from 'src/types/enum/AiEngine'
import {CvEvaluationTypeList} from 'src/types/enum/CvEvaluationType'
import {DropdownMenu} from 'src/components/DropdownMenu/DropdownMenu'
import {AiCvEvaluationResendButton} from 'src/resources/ai-cv-evaluation-request/AiCvEvaluationResendButton'

const ActionFields = () => {
    return (<DropdownMenu>
        <AiCvEvaluationResendButton buttonView={'menuItem'}/>
    </DropdownMenu>)
}
const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'AiCvEvaluationRequest'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>

        </Filter>
    )
}

const AiCvEvaluationRequestList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="AI Генерация вакансий"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'No Data'} description={'You can create'}
                              buttonText={'Create'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'} type={'show'}/>
                <SelectField source={'engine'} label={'Provider'} choices={AiEngineList}/>
                <SelectField source={'type'} label={'Type'} choices={CvEvaluationTypeList}/>
                <SelectField source={'status'} label={'Status'} choices={AiCvRequestStatusList}/>
                <CvLinkField source={'cv'} label={'Cv'}/>
                <VacancyLinkField source={'vacancy'} label={'Vacancy'}/>
                <DateField source={'finishAt'} label={'Finished'} showTime/>
                <FunctionField source={'reqDuration'} label={'Duration'} render={(i: any) => `${(i.reqDuration / 1000).toFixed(2)} сек`}/>
                <TextField source={'result.percentEvaluation'} label={'Evaluation'} choices={AiCvRequestStatusList}/>
                <DateField source={'createdAt'} label={'Created'} showTime/>
                <ActionFields/>
            </Datagrid>
        </List>
    )
}

export default AiCvEvaluationRequestList
