import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid, TextField, SelectInput,DateField,
} from 'react-admin'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'periodStart': 'Start',
            'periodEnd': 'End',
            'hirerRegistre': 'B2B Regs',
            'companyActions': 'Company Actions',
            'vacancyPublished': 'Vacancy published',
            'candidateCreated': 'Candidates created',
            'employeeRegistre': 'Employee Regs',
        },
        data: posts.map((item: any) => ({
            ...item,
            periodStart: formatCsvDate(item.periodStart),
            periodEnd: formatCsvDate(item.periodEnd)
        })),
        fileName: 'Company'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}s>
            <SelectInput source={'period'} variant={'outlined'} alwaysOn={true} choices={[{name: 'Day', id: 'DAY'}, {name: 'Week', id: 'WEEK'}, {name: 'Month', id: 'MONTH'}]}/>
            <DateRangeFilterInput source={'duration:$between'} label="Date Range"
                                  useUTC={true} alwaysOn/>
         </Filter>
    )
}


const DashboardCompanyPeriodList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filterDefaultValues={{period: 'WEEK'}}
            filters={<_Filter/>}
            title="Companies"

            sort={{field: 'periodStart', order: 'DESC'}}
            empty={<EmptyList title={'No Data'} hasCreate={false}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <DateField source={'periodStart'} label={'Start'}  />
                <DateField source={'periodEnd'} label={'End'}  />
                <TextField source={'hirerRegistre'} label={'B2B Regs'} />
                <TextField source={'companyActions'} label={'Company Actions'} />
                <TextField source={'vacancyPublished'} label={'Vacancy published'} />
                <TextField source={'candidateCreated'} label={'Candidates created'} />
                <TextField source={'employeeRegistre'} label={'Employee Regs'} />

            </Datagrid>
        </List>
    )
}

export default DashboardCompanyPeriodList
