import * as React from 'react'
import {Button, useNotify, useRefresh} from 'react-admin'
import {useMemo, useState} from 'react'
import MenuItem from '@mui/material/MenuItem'
import { useRecordContext} from 'ra-core'
import {FieldValues} from 'react-hook-form'
import {RequestError} from 'src/types/types'
import AiCvRequestRepository from 'src/data/repositories/AiCvRequestRepository'
interface Props {
  buttonView: 'button' | 'menuItem'
}

export const AiCvRequestResendButton = (props: Props) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const record = useRecordContext()
  const [sending, setSending] = useState(false)
  const handleClick = async (data: FieldValues) => {
    if(sending){
      return
    }
    try {
      setSending(true)
        await AiCvRequestRepository.resend({id: record.id!})
        notify('Запрос отправлен', { type: 'success' })
      refresh()

    }catch (e) {
      console.error(e)
      if (e instanceof RequestError) {
        notify(e.message, { type: 'error' })
      }
    }
    setSending(false)
    return true
  }
  const title = useMemo<string>(() => {
    return 'Перераспознать'
  }, [])
  const buttonView = useMemo(() => {
    switch (props.buttonView) {
      case 'button':
        return <Button type={'button'} size={'small'} onClick={handleClick} label={title}/>
      case 'menuItem':
        return (<MenuItem onClick={handleClick}>
          {title}
        </MenuItem>)
    }
  }, [])
 return <>
   {buttonView}
 </>
}
