
export enum AiCvRequestStatus {
    Created = 'created',
    InProgress = 'inProgress',
    Finished = 'finished',
    Error = 'error',
    InQueue = 'inQueue',
    NotCompleted = 'notCompleted',
    MovedToProfile = 'movedToProfile',

}
export const AiCvRequestStatusList = [
    {id: AiCvRequestStatus.Created, name: 'Created'},
    {id: AiCvRequestStatus.InProgress, name: 'In Progress'},
    {id: AiCvRequestStatus.Finished, name: 'Finished'},
    {id: AiCvRequestStatus.Error, name: 'Error'},
    {id: AiCvRequestStatus.InQueue, name: 'In Queue'},
    {id: AiCvRequestStatus.NotCompleted, name: 'Not completed'},
    {id: AiCvRequestStatus.MovedToProfile, name: 'Moved to profile'}
]
